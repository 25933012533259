@import "./bootstrap-overides.scss";

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";
@import "./custom.scss";
@import "./components.scss";

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;

  font-family: "HK Grotesk", "HK Nova", -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: $breakpoint-md) {
    font-size: 14px;
  }
}

body {
  &.mobile-menu {
    overflow-y: hidden;
  }
}

a {
  color: inherit;
  text-decoration: underline;
}

a:not(.underlined),
a:not(.underlined):hover,
a:not(.underlined):focus {
  text-decoration: none;
}

a.disabled {
  opacity: 0.8;

  &:hover {
    color: inherit;
    cursor: not-allowed;
  }
}

* {
  box-sizing: border-box;
}

.screenreader-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
