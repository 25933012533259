$black: #4a4a4a;
$white: #ffffff;
$primary: #eb5e00;
$blue-light: #809bc4;
$p-blue: #5076AF;
$purple-light: #f0f1f7;
$blue: #00378a;
$gray: #919291;
$red: #e1001a;
$shadow: #4a4a4a;
$box-shadow: 0 2px 8px 0 rgba(74, 74, 74, 0.1);

// overides
$theme-colors: (
  'blue': $blue,
  'primary': $primary,
  'gray': $gray,
  'dark': $black,
  'red': $red,
  'shadow': $shadow,
);
$navbar-dark-color: $white;
$link-hover-color: $primary;
$dropdown-link-color: $primary;
