.page-container {
  max-width: $max-width;
  margin: auto;
  @media (max-width: $breakpoint-xl) {
    padding-left: $tablet-mobile-spacing;
    padding-right: $tablet-mobile-spacing;
  }
  &-title,
  &-subtitle {
    width: auto;
    margin: auto;
    color: $blue;
  }

  &-title {
    margin: $spacing-desktop-sm auto;
    @media (max-width: $breakpoint-lg) and (min-width: $breakpoint-md) {
      margin: $spacing-desktop-md auto $spacing-desktop-lg;
    }
    @media (min-width: $breakpoint-lg +1) {
      margin: $spacing-desktop-md auto;
    }
    &.duurzaamheid {
      margin-bottom: 0;
    }
  }
  > .duurzaamheid-description {
    color: $p-blue;
    margin-bottom: $spacing-desktop-lg;
    padding: 0;
    font-size: $h4-font-size;
    line-height: 30px;
    font-family: HK Nova;
    @media (max-width: $breakpoint-md) {
      font-size: $label-font-size;
      line-height: 26px;
      margin-bottom: $spacing-desktop-md;
    }
  }

  &-subtitle {
    color: #5076af;
  }

  &-description {
    max-width: 920px;
    background-color: $white;
    padding: $spacing-desktop-sm;
    margin: 0 auto $spacing-desktop-sm;
    @media (min-width: $breakpoint-md) {
      max-width: 770px;
      margin: 0 auto spacerBase10(3);
    }
    @media (min-width: $breakpoint-md) {
      padding-left: spacerBase10(8);
      padding-right: spacerBase10(16);
      padding-top: spacerBase10(4);
      padding-bottom: spacerBase10(4);
    }
  }
  &-link {
    max-width: 920px;
    margin: auto;
    > a {
      display: flex;
      color: $blue;
      align-items: center;
      > span {
        margin-right: $spacing-desktop-xs;
        align-self: center;
      }
    }
    @media (max-width: $breakpoint-md) {
      padding-left: $spacing-desktop-sm;
    }
    @media (min-width: $breakpoint-md) {
      max-width: 770px;
    }
  }
}

// back-button
.page-back {
  display: flex;
  color: $blue;
  align-items: center;
  margin-bottom: $spacing-desktop-md;
  > span {
    margin-left: $spacing-desktop-xs;
  }
}
