.main-page-container {
  margin: $tablet-mobile-spacing auto;
  // min-height: 100vh;
  @media (min-width: $breakpoint-xl) {
    margin: $spacing-desktop-lg auto;
  }
}
// container overwrites
.container {
  &-fluid {
    padding-left: $tablet-mobile-spacing;
    padding-right: $tablet-mobile-spacing;
    @media (min-width: $breakpoint-xl) {
      padding-left: $spacing-desktop-lg;
      padding-right: $spacing-desktop-lg;
    }
  }
}
