// spacings
$spacing-mobile-xs: 1px;
$spacing-desktop-xs: 10px;

$spacing-mobile-sm: 2px;
$spacing-desktop-sm: 20px;

$spacing-mobile-md: 4px;
$spacing-desktop-md: 40px;

$spacing-mobile-lg: 8px;
$spacing-desktop-lg: 80px;

$max-width: 1110px; // applied ot apge-container class

$base: 10px;
// $spacing-desktop-lg: 70px;
$tablet-mobile-spacing: $spacing-desktop-sm;

// bootstrap overwrites
$btn-padding-y-lg: 12px;
$btn-font-size-lg: 3.25rem;
$navbar-nav-link-padding-x: 0;
$card-spacer-x: $spacing-desktop-sm;

// fonts
$default-font: 16px;
$h1-font-size: 4rem; // 64px
$h2-font-size: 2rem; // 32px
$h3-font-size: 1.5rem; // 24px
$h4-font-size: 1.25rem; // 20px
$label-font-size: 1.125rem; // 18px
$label-small-font-size: 12px;

// break points
$breakpoint-sm: 576px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px; // tablet
$breakpoint-xl: 1200px;
